.MenuItem {
  font-weight: bold;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #0077FF;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0077FF;
}

.rdrDefinedRangesWrapper {
  display: none;
}

/* Increase the specificity */
/* .MenuItem.Mui-selected {
  color: #5285F2;
  background: #F0F7FF !important;
  border-left: 3px solid #5285F2;
} */

/* fieldset {
  border: 0px !important;
} */

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0px !important;
}

.rdrCalendarWrapper {
  background-color: #F0F7FF !important;
  border-radius: 8px;
}